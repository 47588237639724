/* eslint-disable no-unused-vars */
import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { publicLinks } from "./links";
import Footer from "../layouts/Footer";
import Appbar from "../layouts/Appbar";
import CircularProgress from "@mui/material/CircularProgress";

const Home = React.lazy(() => import("../pages/Home"));
const Shop = React.lazy(() => import("../pages/Shop"));
const Services = React.lazy(() => import("../pages/Services"));
const Careers = React.lazy(() => import("../pages/Careers"));
const About = React.lazy(() => import("../pages/About"));
const Programs = React.lazy(() => import("../pages/Programs"));

function BaseRouter() {
  return (
    <Router>
      <Suspense
        fallback={
          <>
            <div>
              <CircularProgress
                sx={{
                  color: "#123c66",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </div>
          </>
        }
      >
        <Appbar />
        <Routes>
          <Route exact path={publicLinks.Home} element={<Home />} />
          <Route path={publicLinks.Shop} element={<Shop />} />
          <Route path={publicLinks.Services} element={<Services />} />
          <Route path={publicLinks.Careers} element={<Careers />} />
          <Route path={publicLinks.About} element={<About />} />
          <Route path={publicLinks.Programs} element={<Programs />} />
        </Routes>
        <Footer />
      </Suspense>
    </Router>
  );
}

export default BaseRouter;
