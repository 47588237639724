const publicLinks = {
  Home: "/",
  Shop: "/shop",
  Services: "/services",
  Careers: "/careers",
  About: "/about",
  Programs: "/programs",
  MeritMeBusiness: "https://meritme-business.vercel.app/",
  MeritMeCustomer: "https://meritme.vercel.app/",
};

export { publicLinks };
