/* eslint-disable no-unused-vars */
import React from "react";
import Logo from "../assets/images/logos/black.svg";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import "../assets/styles/Footer.css";
import { footerLinks, footerServices, reachUs } from "../data/footerData";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";


function Footer() {
  return (
    <>
      <Divider style={{ backgroundColor: "#d5d5d5" }} />
      <Box
        sx={{ flexGrow: 1 }}
        style={{ backgroundColor: "#f5f5f7", padding: "40px 20px" }}
      >
        <CssBaseline />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <div>
              <img src={Logo} alt="Tech Africa" />
            </div>
          </Grid>

          <Grid item xs={12} sm={3}>
            <div>
              <Typography variant="h6" component="h6">
                Links
              </Typography>
              <hr style={{ marginBottom: 0 }} />
              <List>
                {footerLinks?.map((item) => (
                  <ListItem key={item.id} disablePadding>
                    <ListItemButton
                      component="a"
                      href={item.link}
                      sx={{ textAlign: "center", color: "#000" }}
                    >
                      {item.label}
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>

          <Grid item xs={12} sm={3}>
            <div>
              <Typography variant="h6" component="h6">
                Services
              </Typography>
              <hr style={{ marginBottom: 0 }} />
              <List>
                {footerServices?.map((item) => (
                  <ListItem key={item.id} disablePadding>
                    <ListItemButton
                      component="a"
                      href={item.link}
                      sx={{ textAlign: "center", color: "#000" }}
                    >
                      {item.name}
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>

          <Grid item xs={12} sm={3}>
            <div>
              <Typography variant="h6" component="h6">
                Reach Us
              </Typography>
              <hr style={{ marginBottom: 0 }} />
              <List>
                {reachUs?.map((item) => (
                  <ListItem key={item?.id} disablePadding>
                    <ListItemButton
                      component="a"
                      href={item.link}
                      sx={{ textAlign: "center", color: "#000" }}
                    >
                      {item?.label}
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
        </Grid>
        <div>
          <Typography>
            © 2024 Dalienst Tech Africa. All rights reserved.
          </Typography>
        </div>
      </Box>
    </>
  );
}

export default Footer;
