import { publicLinks } from "../constants/links";

const footerLinks = [
  { id: 1, label: "Home", link: publicLinks?.Home },
  { id: 2, label: "Services", link: publicLinks?.Services },
  { id: 3, label: "Careers", link: publicLinks?.Careers },
  // { id: 4, label: "About", link: publicLinks?.About },
  { id: 4, label: "2024 Software Engineering", link: publicLinks?.Programs },
];

const footerServices = [
  {
    id: 1,
    name: "Consultancy & Advisory",
  },
  {
    id: 2,
    name: "Software & Applications",
  },
  {
    id: 3,
    name: "Networking & IoT",
  },
  {
    id: 4,
    name: "Web Development",
  },
  {
    id: 6,
    name: "Mobile Development",
  },
  {
    id: 5,
    name: "Personal Branding",
  },

  {
    id: 7,
    name: "Training & Education",
  },
  {
    id: 8,
    name: "Company Branding",
  },
];

const footerPartners = [
  {
    id: 1,
    name: "Dalienst Africa",
  },
];

const reachUs = [
  {
    id: 1,
    label: "daltechafrica@gmail.com",
    link: "mailto:daltechafrica@gmail.com",
  },
  {
    id: 2,
    label: "+254710584581",
    link: "tel:+254710584581",
  },
  {
    id: 3,
    label: "www.daltech.africa",
    link: "https://www.daltech.africa",
  },
  {
    id: 4,
    label: "P.O Box: 10541-80101 Bamburi",
  },
  {
    id: 5,
    label: "Mombasa, Kenya",
  },
];

export { footerLinks, footerServices, footerPartners, reachUs };
